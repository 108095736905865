
                    @import "@/base/global-css/_sass-includes.scss";
                

.Page-section-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}
.u-fade-black {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.u-fade-black::before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    transparent 0,
    transparent 70%,
    rgba(0, 0, 0, 0.05) 80%,
    rgba(0, 0, 0, 0.1) 90%,
    rgba(0, 0, 0, 0.2) 100%
  );
}
.Heading-light {
  font-weight: 300;
}
.ictio-blue-border {
  min-height: $space-sm;
  background-color: #0084bf;
}
.footer-links {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include breakpoint(medium) {
    flex-direction: row;
  }
}
