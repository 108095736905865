
                    @import "@/base/global-css/_sass-includes.scss";
                









































































.common-names {
  @include text(3);
  @include stack-sm;
  .bold {
    font-weight: bold;
  }
}
