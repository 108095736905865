
                    @import "@/base/global-css/_sass-includes.scss";
                





















































































































































































































































































































































































































































































































































































































.ResultsGallery {
  width: 100%;
  .ResultsGallery-background {
    background-color: $color-white;
  }
  .ResultsGallery-row {
    display: flex;
    flex-direction: row;
    max-width: 100%; // needed?
    overflow-x: hidden; // needed?
  }
  .ResultsGallery-link {
    cursor: pointer;
    text-decoration: none;
  }
  .ResultsGallery-figure {
    position: relative;
    margin: 0;
  }
  .ResultsGallery-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    @include text(2);
    @include inset-xs;
    @include colorReverse;
    background-color: $color-overlay-black;
    margin: 0;
    opacity: 0;
    transition: opacity 200ms ease;
    &.show {
      opacity: 1;
    }
    &.withPlayButton {
      padding-left: 3rem;
      [dir="rtl"] & {
        padding-left: 0;
        padding-right: 3rem;
      }
    }
    strong {
      padding-right: $space-sm;
      [dir="rtl"] & {
        padding-left: $space-sm;
        padding-right: 0;
      }
    }
  }
  .ResultsGallery-image {
    display: block;
    max-width: none;
    transition: opacity 200ms ease, transform 200ms ease;
    opacity: 0;
    &.show {
      opacity: 1;
    }
    &.crop {
      object-fit: cover;
    }
  }
  .ResultsGallery-placeholder {
    background-color: $color-neutral-2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-neutral-5;
    @include text(2);
    .Icon {
      fill: $color-neutral-3;
    }
  }
  .ResultsGallery-playButton {
    background-color: $color-primary;
    color: $color-white;
    line-height: 1;
    position: absolute;
    left: $space-sm;
    [dir="rtl"] & {
      left: auto;
      right: $space-sm;
    }
    bottom: $space-sm;
    border-radius: $radius-sm;
    margin: 0;
    @include inset-squish-sm;
  }
  .ResultsGallery-icon {
    @include icon-sm;
    fill: $color-alert;
  }
}
