
                    @import "@/base/global-css/_sass-includes.scss";
                





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@keyframes lightboxFadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  99% {
    display: block;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

body[data-noscroll] {
  overflow: hidden;
}

.Lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1006;
  animation: 0.2s ease lightboxFadeIn;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity 0.2s;
  }
  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }
  .Lightbox-close {
    position: absolute;
    top: $space-sm;
    right: $space-sm;
    [dir="rtl"] & {
      right: auto;
      left: $space-sm;
    }
    @include inset-sm;
    z-index: 1007;
    background-color: $color-neutral-1;
    line-height: 1;
    .Icon {
      fill: $color-neutral-6;
    }
    &:hover {
      background-color: $color-neutral-0;
    }
  }
  .Lightbox-navigation {
    display: flex;
    gap: $space-xs;
    @include inset-xs;
    .Lightbox-toggleDetails {
      display: flex;
      justify-content: center;
      align-items: center;
      .Icon {
        @include icon-sm;
        transition: transform 0.2s ease;
      }
    }
    .Lightbox-toggleDetails,
    .Lightbox-previous,
    .Lightbox-next {
      flex: 1 0 auto;
      @include text(2);
      @include inset-sm;
      background-color: $color-neutral-1;
      color: $color-neutral-6;
      &[disabled] .Icon {
        fill: $color-neutral-2;
      }
      &:hover:not([disabled]) {
        background-color: $color-neutral-0;
      }
      // getting more results
      .Icon--spinner {
        fill: $color-secondary !important;
      }
    }
    @include breakpoint(medium) {
      .Lightbox-toggleDetails {
        display: none;
      }
    }
    @include breakpoint(medium) {
      position: fixed;
      width: 100%;
      top: 0;
      padding: 0;
      .Lightbox-previous,
      .Lightbox-next {
        position: absolute;
        top: -20px;
      }
      .Lightbox-previous {
        left: $space-sm;
        [dir="rtl"] & {
          left: auto;
          right: $space-sm;
        }
      }
      .Lightbox-next {
        right: $space-sm;
        [dir="rtl"] & {
          right: auto;
          left: $space-sm;
        }
      }
    }
  }
  .Lightbox-carousel {
    list-style: none;
    margin: 0;
    display: flex;
    height: 100%;
    overflow-y: auto;
    //scroll-snap-type: x mandatory;
    // hide the scrollbar
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    // Safari 10.1+
    // https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        scroll-snap-type: x mandatory;
      }
    }
  }
  .Lightbox-slide {
    height: 100%;
    width: 100vw;
    display: flex;
    flex: 1 0 auto;
    scroll-snap-align: center;
    outline: 0;
  }
  .Lightbox-figure {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .Lightbox-media-outer {
    flex-grow: 1;
    height: 100%;
    position: relative;
  }
  .Lightbox-media-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // hide overflow unfortunately needed for mobile
    // when expanding details with audio or video player above
    overflow: hidden;
    &.fade-enter-active,
    &.fade-leave-active {
      transition: opacity 0.2s;
    }
    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }
    .Lightbox-image {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto !important;
      // Safari 10.1+
      // https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome
      // this is fine with all browsers except IE 11, which is why we need safari only
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          position: absolute;
        }
      }
    }
    .SpectrogramPlayer {
      width: 100%;
      max-width: 70rem;
    }
  }
  .Lightbox-caption {
    flex-grow: 0;
    justify-self: flex-end;
    @include text(2, loose);
    .Lightbox-obs {
      .Icon {
        @include icon-sm;
        @include inline-xs;
        fill: $color-neutral-3;
        flex-shrink: 0;
      }
    }
    .Lightbox-obs,
    .Lighbox-meta {
      > div {
        display: flex;
        align-items: center;
        max-width: 100%;
      }
    }
    .Lightbox-meta-label {
      @include text(1);
      @include inline-xs;
      color: $color-neutral-4;
      text-transform: uppercase;
    }
    .Lightbox-links {
      a {
        @include text(2);
        display: inline-flex;
        align-items: center;
        .Icon {
          @include icon-sm;
          @include inline-xs;
          fill: currentColor;
        }
      }
    }
  }
  @include breakpoint(medium) {
    .Lightbox-caption-main,
    .Lightbox-caption-details {
      // IE 11 fallback
      display: flex;
      justify-content: space-between;
      > * {
        margin-right: $space-sm;
      }
      @supports (display: grid) {
        // make parent be grid or flex parent
        // and children be grid or flex children
        display: contents;
        > * {
          margin-right: 0;
        }
      }
    }
    .Lightbox-caption-details {
      max-height: none !important;
      overflow: visible !important;
    }
  }
  .Lightbox-caption-inner {
    &.fade-enter-active,
    &.fade-leave-active {
      transition: opacity 0.2s;
    }
    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .Lightbox-speciesLabels {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      @include stack-sm;
      @include breakpoint(medium) {
        margin: 0;
      }
    }
    .Lightbox-galleryLabels {
      word-break: break-word;
    }
    .Lightbox-unconfirmed {
      @include inline-xs;
      color: $color-alert;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      .Icon {
        @include icon-sm;
      }
      span {
        display: none;
        @include text(1);
        text-transform: uppercase;
      }
      @include breakpoint(large) {
        @include inline-sm;
        .Icon {
          @include icon-md;
          @include stack-xs;
        }
        span {
          display: block;
        }
      }
    }
    @include inset-sm;
    @include breakpoint(medium) {
      @include inset-md;
      display: grid;
      gap: $space-sm;
      justify-content: stretch;
      grid-template-columns: minmax(auto, 50%) minmax(auto, 50%) minmax(
          auto,
          25%
        );
      .Lightbox-heading {
        grid-column: 1/3;
        grid-row: 1/2;
      }
      .Lightbox-obs {
        grid-column: 1/2;
        grid-row: 2/2;
      }
      .Lightbox-meta {
        grid-column: 2/3;
        grid-row: 2/2;
      }
      .Lightbox-links {
        grid-column: 3/3;
        grid-row: 2/2;
      }
      .Lightbox-rating {
        grid-column: 3/3;
        grid-row: 1/2;
      }
    }
    @include breakpoint(large) {
      overflow-y: visible;
    }
  }
}

@mixin Lightbox-side {
  .Lightbox-figure {
    flex-direction: row;
  }
  .Lightbox-media-inner {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    height: 100%;
  }
  .Lightbox-caption {
    width: 20rem;
    max-width: 33vw;
    @include breakpoint(large) {
      max-width: 25vw;
    }
    padding: 3rem 0;
    border-left: 1px solid $color-neutral-1;
    display: flex;
    align-items: center;
    [dir="rtl"] & {
      border-left: 0;
      border-right: 1px solid $color-neutral-1;
    }
    .Lightbox-speciesLabels {
      margin-bottom: $space-sm !important;
    }
  }
  .Lightbox-caption-inner {
    display: flex;
    height: calc(100% - 3rem);
    overflow-y: auto;
    // overflow-x: visible is not possible, overflow-y overides it
    // hack needed for rating widget to fully show
    // https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
    padding-left: 250px;
    margin-left: calc(-250px + 1rem);
  }
  .Lightbox-toggleDetails {
    display: none !important;
  }
  .Lightbox-caption-details {
    max-height: none !important;
    overflow: visible !important;
  }
  .Lightbox-navigation {
    position: fixed;
    width: auto;
    padding: 0;
    background-color: $color-white;
    top: auto !important;
    bottom: $space-sm;
    right: $space-sm;
    [dir="rtl"] & {
      right: auto;
      left: $space-sm;
    }
    .Lightbox-previous,
    .Lightbox-next {
      right: auto;
      left: auto;
      top: auto;
      position: relative;
      [dir="rtl"] & {
        right: auto;
        left: auto;
      }
    }
  }
  &.Lightbox--reverse {
    .Lightbox-close {
      background-color: $color-neutral-6;
    }
    .Lightbox-navigation {
      .Lightbox-previous,
      .Lightbox-next {
        background-color: $color-neutral-6;
      }
    }
    .Lightbox-caption {
      border: 0;
    }
  }
}

.Lightbox {
  @include breakpoint(large, down) {
    @media (orientation: landscape) {
      @include Lightbox-side;
    }
  }
}

.Lightbox--side {
  @include breakpoint(large) {
    @include Lightbox-side;
  }
}

.Lightbox--reverse {
  background-color: $color-neutral-6;
  color: $color-white;
  .Lightbox-close {
    background-color: rgba(64, 64, 64, 0.5);
    .Icon {
      fill: $color-neutral-0;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .Lightbox-navigation {
    background-color: $color-neutral-5;
    .Lightbox-toggleDetails,
    .Lightbox-previous,
    .Lightbox-next {
      background-color: $color-neutral-6;
      color: $color-neutral-2;
      &[disabled] .Icon {
        fill: $color-neutral-4;
      }
      &:hover:not([disabled]) {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    @include breakpoint(medium) {
      background-color: transparent;
      .Lightbox-previous,
      .Lightbox-next {
        color: $color-neutral-0;
        background-color: rgba(64, 64, 64, 0.5);
      }
    }
  }
  .Lightbox-caption {
    background-color: $color-neutral-5;
    border: 0;
    a {
      color: $color-link-reverse;
    }
  }
  .Lightbox-caption-inner {
    .Lightbox-meta-label {
      color: $color-neutral-2;
    }
  }
}
